<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent overlay-opacity="0.8">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              {{ $t("favoritesPage.createDialogTitle") }}
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn @click="$emit('close')" icon><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="pt-4" style="font-size: 1.3rem">
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newCollecton.name"
                  flat
                  :label="$t('favoritesPage.label')"
                  outlined
                  solo
                  @keyup.enter="createCollection"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn
            block
            text
            :disabled="!newCollecton.name"
            @click="createCollection"
            :loading="loading"
            class="text-capitalize"
            style="font-size: 1.1rem; letter-spacing: 0px"
          >
            {{ $t("favoritesPage.create") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog"],
  data() {
    return {
      newCollecton: {},
      loading: false,
    };
  },
  methods: {
    createCollection() {
      if (this.newCollecton.name) {
        this.loading = true;
        this.$store
          .dispatch("favoritesModule/createCollection", {
            body: this.newCollecton,
          })
          .then(() => {
            this.$emit("done");
          })
          .catch(() => {
            this.$buefy.toast.open({
              message: this.$t("app.errorNotif"),
              type: "is-danger",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
